.contacts {
  padding: 50px 0;
  &__title {
    font-size: 40px;
    font-family: 'BebasNeue', sans-serif;
    margin-bottom: 50px;
    @include mobile {
      font-size: 30px;
    }
  }
  &__map {
    position: relative;
    margin-left: 20px;
    img {
      max-width: 100%;
    }
    @include mobile {
      margin-left: 0;
    }
  }
  #map {
    @include mobile {
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-right: -20px;
    }
  }
  &__info {
    position: absolute;
    top: -20px;
    width: 377px;
    background: #232527;
    padding: 30px;
    z-index: 1000;
    color: #fff;
    font-weight: bold;
    line-height: 35px;
    border-radius: 10px;
    left: -20px;
    @include mobile {
      position: relative;
      width: 100%;
      top: 0;
      left: 0;
      margin-bottom: -30px;
    }
    button {
      line-height: 1;
      font-size: 24px;
      font-family: 'BebasNeue', sans-serif;
      color: #fff;
      height: 54px;
      background: transparent;
      border: 2px solid #404040;
      border-radius: 10px;
      padding: 0 45px;
      font-weight: normal;
      margin-top: 10px;
    }
  }
}
