$black: #1f2732;
$gray: #676f7a;
$city_lights: #dfe6e9;
$sooting_breeze: #b2bec3;
//---//
$greenish_blue: #55efc4;
$mint_leaf: #00b894;
//---//
$faded_poster: #81ecec;
$egg_blue: #00cec9;
//---//
$electron_blue: #0984e3;
$bright_yarrow: #fdcb6e;
