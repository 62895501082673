.about {
  padding: 50px 0;
  &__title {
    font-size: 40px;
    font-family: 'BebasNeue', sans-serif;
    margin-bottom: 30px;
    @include mobile {
      font-size: 30px;
    }
  }
  &__wrap {
    display: flex;
    @include mobile {
      flex-direction: column;
    }
  }
  &__left {
    flex: 1;
    margin-right: 60px;
    @include mobile {
      margin-right: 0;
      flex: none;
      margin-bottom: 30px;
    }
  }
  &__right {
    flex: 1.5;
    em {
      color: #fd3041;
      font-family: 'BebasNeue', sans-serif;
      font-size: 20px;
    }
    p {
      font-size: 14px;
      line-height: 21px;
      color: #666163;
      margin-bottom: 30px;
      @include mobile {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 25px;
      }
    }
    strong {
      display: block;
      font-size: 26px;
      color: #231f20;
      font-family: 'BebasNeue', sans-serif;
      margin-bottom: -5px;
    }
  }
  &__features {
    display: flex;
    flex-wrap: wrap;
    & > li {
      flex: 50%;
      &:first-child,
      &:nth-child(2) {
        border-bottom: 2px solid #d2d2d5;
        padding-bottom: 40px;
        margin-bottom: 30px;
      }
    }
    &-val {
      color: #fd3041;
      font-size: 100px;
      font-family: 'BebasNeue', sans-serif;
      @include mobile {
        font-size: 80px;
      }
    }
    &-label {
      font-size: 14px;
      color: #666163;
      font-weight: bold;
    }
  }
}
