.clients {
  padding: 50px 0;
  background: #f4f3f4;
  &__title {
    font-size: 40px;
    font-family: 'BebasNeue', sans-serif;
    margin-bottom: 30px;
    @include mobile {
      font-size: 30px;
    }
  }
}

.client {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 2px solid #fff;
  padding: 10px;
  @include mobile {
    height: 90px;
  }
  img {
    max-width: 160px;
    max-height: 80px;
    @include mobile {
      max-width: 100%;
    }
  }
}

.client-slider {
  padding-bottom: 50px;
  .swiper-pagination {
    bottom: 0;
  }
  .swiper-pagination-bullet-active {
    background: #fd3041;
  }
}
