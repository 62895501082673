.social {
  &__list {
    display: flex;
  }
  &__item {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  &__link {
    display: block;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: #a5a5ac;
    text-align: center;
    line-height: 33px;
    position: relative;
  }
}
