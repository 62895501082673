.footer {
  background: #f4f3f4;
  &__inner {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mobile {
      flex-direction: column;
      height: auto;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 30px 0;
    }
  }
  &__menu {
    display: flex;
    @include mobile {
      flex-wrap: wrap;
      margin: 20px -15px;
    }
    li {
      @include mobile {
        width: 26%;
      }
    }
    a {
      display: inline-block;
      padding: 5px 15px;
      font-size: 14px;
      line-height: 1;
    }
  }
  &__copyright {
    font-size: 13px;
    color: #666163;
    line-height: 18px;
    @include mobile {
      order: -1;
    }
  }
}
