.hero {
  background: #1d1e20;
  padding: 174px 0 70px;
  @include mobile {
    padding: 95px 0 45px;
    overflow: hidden;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    position: relative;
    @include mobile {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  &__left {
    & > ul {
      display: flex;
      flex-direction: column;
    }
    & > ul > li {
      font-size: 90px;
      font-family: 'BebasNeue', sans-serif;
      line-height: 120px;
      color: #fff;
      letter-spacing: 5px;
      position: relative;
      display: flex;
      align-items: center;
      width: 620px;
      @include mobile {
        width: 100%;
        font-size: 48px;
        line-height: 70px;
        letter-spacing: normal;
        flex-direction: column;
        align-items: flex-start;
      }
      .line {
        height: 7px;
        flex: 1;
        background: #fd3041;
        margin-top: -20px;
        position: absolute;
        width: 0;
        @include mobile {
          display: none;
        }
      }
      .name {
        transition: .3s;
        &:hover {
          color: #fd3041;
        }
      }
      & > .description {
        font-family: 'BebasNeue', sans-serif;
        color: #fff;
        font-size: 20px;
        line-height: 35px;
        letter-spacing: normal;
        position: absolute;
        right: -265px;
        top: 0;
        width: 252px;
        opacity: 0;
        @include mobile {
          position: static;
          opacity: 1;
          width: 100%;
        }
        p {
          color: #fff;
        }
      }
    }
  }
  &__right {
    max-width: 200px;
    align-self: flex-end;
    max-height: 200px;
    transform: rotate(-90deg);
    padding-left: 25px;
    position: absolute;
    right: 0;
    @include mobile {
      position: static;
      transform: none;
      order: -1;
      padding-left: 0;
      max-width: none;
      margin-bottom: 20px;
    }
    div {
      font-family: 'BebasNeue', sans-serif;
      font-size: 103px;
      color: #37383c;
      letter-spacing: 5px;
      @include mobile {
        font-size: 48px;
        letter-spacing: .5px;
      }
    }
  }
}
