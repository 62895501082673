.feedback {
  background: #232527;
  padding: 20px 0;
  position: sticky;
  top: 104px;
  z-index: 10000;
  @include mobile {
    top: 50px;
    padding: 15px 0;
  }
  &__inner {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: #fff;
  }
  &__title {
    text-transform: uppercase;
    font-family: 'BebasNeue', sans-serif;
    font-size: 30px;
    line-height: 42px;
    letter-spacing: .5px;
    @include mobile {
      font-size: 18px;
      line-height: 1;
    }
  }

  &__form {
    margin-left: 45px;
    position: relative;
    @include mobile {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      width: 100%;
      background: #232527;
      top: 100%;
      margin-left: 0;
      padding: 20px;
      display: flex;
      flex-direction: column;
      transition: .15s ease-in-out;
    }
    &.isOpen {
      opacity: 1;
      visibility: visible;
    }
    input {
      height: 30px;
      border: none;
      color: #fff;
      background: transparent;
      border-bottom: 1px solid #404040;
      margin-right: 35px;
      @include mobile {
        margin-right: 0;
        width: 100%;
        margin-bottom: 10px;
      }
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: rgba(white, .5);
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: rgba(white, .5);
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: rgba(white, .5);
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: rgba(white, .5);
      }
    }
  }
  &__submit {
    margin-left: 20px;
    font-size: 24px;
    font-family: 'BebasNeue', sans-serif;
    color: #fff;
    height: 54px;
    width: 168px;
    background: transparent;
    border: 2px solid #404040;
    border-radius: 10px;
    padding: 0 45px;
    @include mobile {
      margin: 10px auto auto;
    }
  }
  &__close {
    display: none;
    position: absolute;
    right: 20px;
    top: 0;
    border: none;
    background: none;
    svg {
      width: 15px;
      height: 15px;
      fill: #ccc;
    }
    @include mobile {
      display: block;
    }
  }
}

.form-message {
  position: absolute;
  top: -10px;
  left: 0;
  font-size: 12px;
}

.form-success {
  height: 54px;
  font-family: 'BebasNeue', sans-serif;
  align-items: center;
  display: none;
  width: 168px;
  justify-content: center;
  margin-left: 20px;
  font-size: 24px;
}
