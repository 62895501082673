.single-project {
  @include mobile {
    padding-top: 50px;
  }
  &__wrap {
    display: flex;
    background: #1d1e20;
    @include mobile {
      flex-direction: column;
    }
  }
  &__left {
    flex-grow: 1;
    flex-shrink: 0;
    color: #fff;
    display: flex;
    justify-content: flex-end;
    min-width: 0;
    position: relative;
    @include mobile {
      flex-direction: column;
    }
  }
  &__right {
    flex-grow: 3;
    width: 800px;
    min-width: 0;
    flex-shrink: 0;
    display: flex;
    @include mobile {
      width: 100%;
      min-width: 0;
      display: block;
      flex: none;
    }
  }
  &__inner {
    max-width: 470px;
    padding: 30px 55px;
    @include mobile {
      max-width: 100%;
      width: 100%;
      padding: 13px;
    }
  }
  &__back {
    font-size: 12px;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    img {
      margin-right: 10px;
    }
  }
  &__title {
    font-family: 'AA Bebas Neue', sans-serif;
    font-size: 40px;
    margin-bottom: 20px;
  }
  &__tags {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 25px;
  }
  &__desc {
    h4 {
      font-size: 14px;
      line-height: 21px;
      text-transform: uppercase;
      margin-bottom: 25px;
    }
    h3 {
      font-weight: bold;
      color: #fd3041;
      font-family: 'AA Bebas Neue', sans-serif;
      display: block;
      margin-bottom: 8px;
      font-size: 20px;
      letter-spacing: 1px;
    }
    p {
      color: #fff;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 25px;
    }
    ul {
      color: #fff;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 25px;
      li {
        &:not(:last-child) {
          margin-bottom: 13px;
        }
        &::before {
          padding-left: 17px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #fd3041;
          position: absolute;
          left: 0;
          top: 5px;
        }
      }
    }
  }
  &__btns {
    position: absolute;
    bottom: 0;
    display: flex;
    right: -85px;
    align-items: center;
    background: #1d1e20;
    height: 60px;
    width: 85px;
    justify-content: center;
    z-index: 10;
    @include mobile {
      position: static;
      left: 0;
      right: auto;
    }
    .swiper-button-next,
    .swiper-button-prev {
      position: static;
      margin-top: 0;
      width: 15px;
      height: 15px;
      background-size: contain;
      background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA0OTIgNDkyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0OTIgNDkyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjY0cHgiIGhlaWdodD0iNjRweCI+CjxnPgoJPGc+CgkJPHBhdGggZD0iTTE5OC42MDgsMjQ2LjEwNEwzODIuNjY0LDYyLjA0YzUuMDY4LTUuMDU2LDcuODU2LTExLjgxNiw3Ljg1Ni0xOS4wMjRjMC03LjIxMi0yLjc4OC0xMy45NjgtNy44NTYtMTkuMDMybC0xNi4xMjgtMTYuMTIgICAgQzM2MS40NzYsMi43OTIsMzU0LjcxMiwwLDM0Ny41MDQsMHMtMTMuOTY0LDIuNzkyLTE5LjAyOCw3Ljg2NEwxMDkuMzI4LDIyNy4wMDhjLTUuMDg0LDUuMDgtNy44NjgsMTEuODY4LTcuODQ4LDE5LjA4NCAgICBjLTAuMDIsNy4yNDgsMi43NiwxNC4wMjgsNy44NDgsMTkuMTEybDIxOC45NDQsMjE4LjkzMmM1LjA2NCw1LjA3MiwxMS44Miw3Ljg2NCwxOS4wMzIsNy44NjRjNy4yMDgsMCwxMy45NjQtMi43OTIsMTkuMDMyLTcuODY0ICAgIGwxNi4xMjQtMTYuMTJjMTAuNDkyLTEwLjQ5MiwxMC40OTItMjcuNTcyLDAtMzguMDZMMTk4LjYwOCwyNDYuMTA0eiIgZmlsbD0iI0ZGRkZGRiIvPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
    }
    .swiper-button-prev {
      margin-right: 8px;
    }
    .swiper-button-next {
      transform: rotate(180deg);
    }
  }
}

.single-project-slider {
  height: 100%;
  width: 100%;
  min-width: 0;
  img {
    display: block;
    height: 100%;
    width: 100%;
    margin: auto;
    object-fit: cover;
    position: absolute;
  }
}
