.projects {
  padding: 50px 0;
  &__inner {
    position: relative;
  }
  &__header {
    padding-bottom: 30px;
  }
  &__title {
    font-size: 40px;
    font-family: 'BebasNeue', sans-serif;
    @include mobile {
      font-size: 30px;
    }
  }
  .tns-controls {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.project {
  position: relative;
  color: #fff;
  &__img {
    height: 490px;
    img {
      display: block;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__title {
    font-size: 30px;
    position: absolute;
    transform: rotate(-90deg);
    left: 30px;
    bottom: 10px;
    font-family: 'BebasNeue', sans-serif;
    transform-origin: 0 0;
  }
  &__type {
    position: absolute;
    bottom: 20px;
    right: 30px;
    font-weight: bold;
    font-size: 14px;
    text-align: right;
    max-width: 60%;
  }
}

.project-slider {
  padding-top: 70px;
  margin-top: -70px;
  .swiper-button-next, .swiper-button-prev {
    top: 30px;
    width: 20px;
    height: 20px;
    background-size: contain;
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev {
    right: 20px;
    left: auto;
  }
}
