.header {
  background: #fff;
  padding: 0 60px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1001;
  @include tablet {
    padding: 0 30px;
  }
  &__inner {
    height: 104px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1160px;
    margin: 0 auto;
    @include mobile {
      height: 50px;
    }
  }
  .social {
    margin-right: 10px;
    @include mobile {
      display: none;
    }
  }
  .logo {
    @include mobile {
      img {
        width: 160px;
      }
    }
  }
}

.menu {
  &__list {
    display: flex;
    @include mobile {
      position: absolute;
      opacity: 0;
      visibility: hidden;
    }
  }
  &__link {
    display: block;
    padding: 15px;
    font-family: 'BebasNeue', sans-serif;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: .5px;
  }
}

.phone {
  font-family: 'BebasNeue', sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 24px;
  color: #fd3041;
  letter-spacing: .5px;
  //&::first-letter {
  //  font-family: 'PT Sans', sans-serif;
  //}
  margin-right: 20px;
  @include mobile {
    display: none;
  }
}

.menu {
  margin: auto;
}

.languages {
  font-size: 14px;
}

.languages-menu {
  display: flex;
}
.languages-item {
  color: #949494;
  &:first-child {
    margin-right: 5px;
    &::after {
      content: '/';
      color: #949494!important;
      font-weight: normal!important;
    }
  }
  &.is-active {
    color: #000;
    pointer-events: none;
    font-weight: bold;
  }
}
