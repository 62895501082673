.team {
  background: #1d1e20;
  padding: 50px 0;
  &__title {
    font-size: 40px;
    font-family: 'BebasNeue', sans-serif;
    margin-bottom: 30px;
    color: #fff;
    @include mobile {
      font-size: 30px;
    }
  }
  &__img {
    border-radius: 6px;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__name {
    font-family: 'BebasNeue', sans-serif;
    font-size: 24px;
    color: #fff;
    margin-top: -25px;
    margin-left: 20px;
    line-height: 26px;
    margin-bottom: 5px;
  }
  &__position {
    font-size: 14px;
    line-height: 24px;
    color: #6b6d73;
    margin-left: 20px;
    margin-bottom: 3px;
  }
  &__contact {
    font-size: 14px;
    line-height: 20px;
    color: #6b6d73;
    margin-left: 20px;
  }
}

.team-slider {
  padding-top: 70px;
  margin-top: -70px;
  .swiper-button-next, .swiper-button-prev {
    top: 30px;
    width: 20px;
    height: 20px;
    background-size: contain;
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev {
    right: 20px;
    left: auto;
  }
}
