html,
body {
  //display: flex;
  min-height: 100%;
}

body {
  flex-direction: column;
  justify-content: space-between;
  font-family: 'PT Sans', sans-serif;
  margin: 0;
  flex: 1;
  color: #231f20;
}

.content {
  padding-top: 104px;
  @include mobile {
    padding-top: 0;
  }
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

::-webkit-scrollbar {
  width: 3px;
  &-track {
    background: transparent;
  }
  &-thumb {
    background: $sooting_breeze;
    &:hover {
      background: $gray;
    }
  }
}

a {
  text-decoration: none;
  color: inherit;
}
