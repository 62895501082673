.js-Accordion {
  margin: 0 auto;
}
.js-Accordion-title {
  cursor: pointer;
  display: block;
}
.js-Accordion-title:first-child {
}
.js-Accordion-content {
  height: 0;
  overflow: hidden;
  transition: all .25s linear;
}
